import React, { useState } from 'react'
import { BsFillImageFill } from 'react-icons/bs'

import './Form.scss'
import { useToasts } from 'react-toast-notifications'
import { FaTimes } from 'react-icons/fa'

const Form = ({ setKyc1 }: any) => {
  const { addToast } = useToasts()
  const [ssn, setSsn] = useState('')
  const [frontImage, setFrontImage] = useState<any>({
    string: 'Upload an image (less than 4mb)',
    file: null,
  })
  const [frontFile, setfrontFile] = useState({})
  const [backImage, setBackImage] = useState<any>({
    string: 'Upload an image (less than 4mb)',
    file: null,
  })
  const [backFile, setBackFile] = useState({})

  const onSelectFrontImage = (e: any) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    if (file.size > 4000000) {
      addToast('Image must be below 4mb', {
        appearance: 'error',
        autoDismiss: true,
      })
    } else {
      reader.onloadend = () => {
        setFrontImage({
          string: file.name,
          file,
        })
        setfrontFile(file)
      }
    }
  }
  const onSelectBackImage = (e: any) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    if (file.size > 4000000) {
      addToast('Image must be below 4mb', {
        appearance: 'error',
        autoDismiss: true,
      })
    } else {
      reader.onloadend = () => {
        setBackImage({
          string: file.name,
          file,
        })
        setBackFile(file)
      }
    }
  }

  const encode = (data: any) => {
    const formData = new FormData()
    Object.keys(data).forEach((k) => {
      formData.append(k, data[k])
    })
    return formData
  }

  const handleSubmit = (e: any) => {
    const data = { 'form-name': 'KYC', ssn, frontFile, backFile }
    console.log(data)

    if (ssn.length !== 9) {
      addToast('SSN must be 9 digits', {
        appearance: 'error',
        autoDismiss: true,
      })
    } else if (
      frontImage.string === 'Upload an image (less than 4mb)' ||
      backImage.string === 'Upload an image (less than 4mb)'
    ) {
      addToast('Submit a valid id image', {
        appearance: 'error',
        autoDismiss: true,
      })
    } else {
      fetch('/', {
        method: 'POST',
        // headers: { "Content-Type": 'multipart/form-data; boundary=random' },
        body: encode(data),
      })
        .then(() => {
          addToast(
            'Kyc 1 submitted Successfully. Please do not attempt except requested to.',
            {
              appearance: 'success',
              autoDismiss: true,
            }
          )
          localStorage.setItem('kyc1', 'true')
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        })
        .catch((error) =>
          addToast('Kyc 1 submission failed.', {
            appearance: 'error',
            autoDismiss: true,
          })
        )
    }

    e.preventDefault()
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target
    if (name === 'ssn') {
      return setSsn(value)
    }
  }
  return (
    <div className='formG'>
      <form onSubmit={handleSubmit} action='/thank-you/'>
        <div className='close'>
          <FaTimes onClick={() => setKyc1(false)} />
        </div>
        <div className='top'>
          <h1>KYC 1 Verification.</h1>
          <p>
            Due to our AML policies, every user is required to underatake KYC
            Verification.
          </p>
        </div>
        <div className='card'>
          <p>SSN: </p>
          <input
            type='text'
            name='ssn'
            value={ssn}
            onChange={handleChange}
            placeholder='Input SSN'
          />
        </div>
        <div className='card'>
          <p>Image of front side of a valid ID card</p>
          <div className='cardx'>
            <input
              type='file'
              name='frontFile'
              id='frontFile'
              className='inputy'
              accept='image/*'
              onChange={onSelectFrontImage}
            />
            <div className='imagee_label'>
              <label htmlFor='frontFile'>
                <p>
                  {frontImage.string}
                  <BsFillImageFill />
                </p>
              </label>
            </div>
          </div>
        </div>
        <div className='card'>
          <p>Image of the back side of the valid ID card</p>
          <div className='cardx'>
            <input
              type='file'
              name='backFile'
              id='backFile'
              className='inputy'
              onChange={onSelectBackImage}
            />
            <div className='imagee_label'>
              <label htmlFor='backFile'>
                <p>
                  {backImage.string}
                  <BsFillImageFill />
                </p>
              </label>
            </div>
          </div>
        </div>
        <p>
          <button type='submit'>Submit</button>
        </p>
      </form>
    </div>
  )
}

export default Form
