import React, { useState, useContext } from 'react'

const AppContext = React.createContext<any | null>(null)

const AppProvider = ({ children }: any) => {
  const [toggleSidebar, setToggleSidebar] = useState<string | boolean>('false')

  const handleSidebarToggle = () => {
    setToggleSidebar(!toggleSidebar)
  }
  return (
    <AppContext.Provider value={{ handleSidebarToggle, toggleSidebar }}>
      {children}
    </AppContext.Provider>
  )
}

export const useGlobalContext = () => {
  return useContext(AppContext)
}
export { AppContext, AppProvider }
