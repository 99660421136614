import React, { useState } from 'react'
import './Auth.scss'
import { Link } from 'react-router-dom'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { useForm } from 'react-hook-form'
import Logo from '../../images/Logo.png'
import { useToasts } from 'react-toast-notifications'

interface Prps {
  Login: any
}
function AdminLogin({ Login }: Prps) {
  const { addToast } = useToasts()
  const [formLoading, setFormLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>()
  const onLogin = (formData: any) => {
    setFormLoading(true)
    fetch(`https://blockchainaisc.herokuapp.com/admin/login`, {
      method: 'post',
      headers: { 'content-Type': 'application/json' },
      body: JSON.stringify({
        ...formData,
        email: formData.email.toLowerCase(),
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            Login(data.token)
            addToast('Login Successful', {
              appearance: 'success',
              autoDismiss: true,
            })
            setTimeout(() => {
              window.location.replace('/admin/home')
            }, 4000)
            setFormLoading(false)
          })
        } else {
          res.json().then((data) => {
            addToast(data.error, {
              appearance: 'error',
              // autoDismiss: true,
            })
            setFormLoading(false)
          })
        }
      })
      .catch((err) => {
        addToast('An error ocurred. Unable to reach server', {
          appearance: 'error',
          autoDismiss: true,
        })
        setFormLoading(false)
      })
  }
  return (
    <div className='auth'>
      <div className='center'>
        <div className='logo'>
          <img
            src={Logo}
            alt=''
            onClick={() => {
              addToast('Success', {
                appearance: 'success',
                autoDismiss: true,
              })
            }}
          />
        </div>
        <form className='form' action='' onSubmit={handleSubmit(onLogin)}>
          <div className='form_head'>
            <h1>Admin Log in</h1>
          </div>
          <div className='form_body'>
            <div className='card'>
              <input
                type='text'
                placeholder='Admin Email'
                // name='email'
                {...register('email', {
                  required: 'Admin Email is required',
                })}
              />
            </div>
            <div className='card'>
              <input
                type='password'
                // name='password'
                id=''
                placeholder='Password'
                {...register('password', { required: 'Enter password' })}
              />
            </div>
          </div>
          <div className='form_bottom'>
            <div className='form_left'>
            </div>
            <div className='form_right'>
              <button>
                <p>Login</p> <AiOutlineArrowRight />
              </button>
            </div>
          </div>
        </form>
        <div className='apply'>
          <div className='apply_cover'>
            <p>
              Don't have an account?{' '}
              <span>
                <Link to='/register'>
                  Create one <AiOutlineArrowRight />
                </Link>
              </span>
            </p>
          </div>
        </div>
        <div className='footers'>
          <p>
            © 2017 Blockchainasic. All Rights Reserved.
            <span>
              <Link to='/'>Privacy Policy</Link>
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default AdminLogin
