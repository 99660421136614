import React, { useState } from 'react'
import './Auth.scss'
import { Link } from 'react-router-dom'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { useForm } from 'react-hook-form'
import Logo from '../../images/Logo.png'
import { useToasts } from 'react-toast-notifications'
import Loader from '../../images/loder.gif.gif'

function Register() {
  const [formLoading, setFormLoading] = useState(false)
  const { addToast } = useToasts()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>()

  const onLogin = (formData: any) => {
    setFormLoading(true)
    fetch(`https://blockchainaisc.herokuapp.com/user/signup`, {
      method: 'post',
      headers: { 'content-Type': 'application/json' },
      body: JSON.stringify({
        ...formData,
        email: formData.email.toLowerCase(),
      }),
    })
      .then((res) => {
        if (res.status === 201) {
          res.json().then((data) => {
            addToast(
              'Account Created Successfully. Verification in progress.',
              {
                appearance: 'success',
                autoDismiss: true,
              }
            )
            setTimeout(() => {
              window.location.replace('/')
            }, 5000)
            setFormLoading(false)
          })
        } else {
          res.json().then((data) => {
            addToast(data.error, {
              appearance: 'error',
              autoDismiss: true,
            })
            setFormLoading(false)
          })
        }
      })
      .catch((err) => {
        addToast('An error ocurred. Unable to reach server', {
          appearance: 'error',
          autoDismiss: true,
        })
        setFormLoading(false)
      })
  }
  return (
    <div className='auth'>
      <div className='center'>
        <div className='logo'>
          <img src={Logo} alt='' />
        </div>
        <form className='form' action='' onSubmit={handleSubmit(onLogin)}>
          <div className='form_head'>
            <h1>Create Account</h1>
          </div>
          <div className='form_body'>
            <div className='card'>
              <input
                type='text'
                placeholder='Full Name'
                // name='email'
                {...register('name', {
                  required: 'PIN/User ID is required',
                })}
              />
            </div>
            <div className='card'>
              <input
                type='email'
                placeholder='Email'
                // name='email'
                {...register('email', {
                  required: 'Email ID is required',
                })}
              />
            </div>
            <div className='card'>
              <input
                type='tel'
                placeholder='Phone number'
                // name='email'
                {...register('phone', {
                  required: 'PIN/User ID is required',
                })}
              />
            </div>
            <div className='card'>
              <input
                type='password'
                id=''
                placeholder='Password'
                {...register('password', { required: 'Enter password' })}
              />
            </div>
          </div>
          <div className='form_bottom'>
            <div className='form_left'></div>
            <div className='form_right'>
              {formLoading ? (
                <img src={Loader} alt='' />
              ) : (
                <button>
                  <p>Create</p> <AiOutlineArrowRight />
                </button>
              )}
            </div>
          </div>
        </form>
        <div className='apply'>
          <div className='apply_cover'>
            <p>
              Already an account?{' '}
              <span>
                <Link to='/login'>
                  Login <AiOutlineArrowRight />
                </Link>
              </span>
            </p>
          </div>
        </div>
        <div className='footers'>
          <p>
            © 2017 Blockchainasic. All Rights Reserved.
            <span>
              <Link to='/'>Privacy Policy</Link>
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Register
