import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Deposit from './Components/Deposit/Deposit'
import Home from './Components/Home/Home'
import Plan from './Components/Plan/Plan'
import Sidebar from './Components/Sidebar/Sidebar'
import Topbar from './Components/Topbar/Topbar'
import Transactions from './Components/Transactions/Transactions'
import Withdraw from './Components/Withdraw/Withdraw'
import './Dashboard.scss'
import Loader from '../../images/loader.gif'
import Form from './Components/Form/form'
import Form2 from './Components/Form/form2'

function Dashboard() {
  const authToken = sessionStorage.getItem('UserId')
  const [loading, setLoading] = useState(true)
  const [userDetails, setUserDetails] = useState({})
  const [Kyc1, setKyc1] = useState(false)
  const [Kyc2, setKyc2] = useState(false)
  const { page } = useParams<string | any>()
  const dashboardPage = () => {
    switch (page) {
      case 'home':
        return (
          <Home userDetails={userDetails} setKyc1={setKyc1} setKyc2={setKyc2} />
        )
      case 'deposit':
        return <Deposit />
      case 'plan':
        return <Plan />
      case 'transaction':
        return <Transactions />
      case 'withdraw':
        return <Withdraw />

      default:
        return <h1>Page not found</h1>
    }
  }

  useEffect(() => {
    fetch(`https://blockchainaisc.herokuapp.com/user/profile`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setUserDetails(data.user)
          setLoading(false)
        })
      }
    })
  }, [])

  return loading ? (
    <div className='loaders'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='main-dashboard'>
      {Kyc1 && <Form setKyc1={setKyc1} />}
      {Kyc2 && <Form2 setKyc2={setKyc2} />}
      <Topbar />
      <div className='main_body'>
        <Sidebar page={page} />
        <div className='main_container'>{dashboardPage()}</div>
      </div>
    </div>
  )
}

export default Dashboard
