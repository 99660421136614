import React, { useState, useEffect } from 'react'
import Loader from '../../../../images/loader.gif'
import { MdOpenInNew } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

interface Props {
  isLoading: boolean
  mergedData: any
}
function Deposits({ mergedData, isLoading }: Props) {
  const navigate = useNavigate()

  return isLoading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='home_page'>
      <div className='home_tag'>
        <h1> Deposits</h1>
      </div>
      <div className='home_tables'>
        <div className='left' style={{ minHeight: '60vh' }}>
          <table>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Name</th>
                <th>Plan</th>
                <th>Amount</th>
                <th>Resloved</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {mergedData
                .filter((item: any) => item.type === 'deposit')
                .map((item: any, i: number) => (
                  <tr key={i + 1}>
                    <td>{i + 1}</td>
                    <td className='count'>{item.owner.name}</td>
                    <td>{item.plan}</td>
                    <td>${item.amount.toLocaleString()}</td>
                    <td>{`${item.success}`}</td>
                    <td>
                      <MdOpenInNew
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate(`/admin/tranedit/${item._id}`)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Deposits
