import React, { useState, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Landing from './Pages/Landing/Landing'
import './App.scss'
import Login from './Pages/Auth/Login'
import Register from './Pages/Auth/Register'
import Dashboard from './Pages/Dashboard/Dashboard'
import AdminLogin from './Pages/Auth/Adminlogin'
import Terms from './Pages/Terms/Terms'
import Privacy from './Pages/Privacy/Privacy'
import ScrollToTop from './Scroll'
import AdminDashboard from './Pages/AdminDashboard/AdminDashboard'
import UserRoute from './UserRoute'
import AdminRoute from './AdminRoute'

function App() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [adminIn, setAdminIn] = useState(false)
  const logUserIn = (admin: string) => {
    sessionStorage.setItem('UserId', admin)
    setLoggedIn(true)
  }
  const logAdminIn = (admin: string) => {
    sessionStorage.setItem('AdminId', admin)
    setAdminIn(true)
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='/terms-of-service' element={<Terms />} />
        <Route path='/privacy-and-policy' element={<Privacy />} />
        <Route path='/login' element={<Login Login={logUserIn} />} />
        <Route path='/admini' element={<AdminLogin Login={logAdminIn} />} />
        <Route path='/register' element={<Register />} />
        <Route element={<UserRoute loggedIn={loggedIn} />}>
          <Route path='/dashboard/:page' element={<Dashboard />} />
        </Route>
        <Route element={<AdminRoute loggedIn={adminIn} />}>
          <Route path='/admin/:page' element={<AdminDashboard />} />
          <Route path='/admin/:page/:id' element={<AdminDashboard />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
