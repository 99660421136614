import React, { useState } from 'react'

import './Form.scss'
import { useToasts } from 'react-toast-notifications'
import { FaTimes } from 'react-icons/fa'

const Form2 = ({ setKyc2 }: any) => {
  const { addToast } = useToasts()
  const [bankName, setBankName] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [routingNumber, setRoutingNumber] = useState('')

  const encode = (data: any) => {
    const formData = new FormData()
    Object.keys(data).forEach((k) => {
      formData.append(k, data[k])
    })
    return formData
  }

  const handleSubmit = (e: any) => {
    const data = { 'form-name': 'KYC2', bankName, accountNumber, routingNumber }
    console.log(data)

    if (bankName.length < 1) {
      addToast('Bank Name is required', {
        appearance: 'error',
        autoDismiss: true,
      })
    } else if (accountNumber.length < 1) {
      addToast('Account Number is required', {
        appearance: 'error',
        autoDismiss: true,
      })
    } else if (routingNumber.length < 1) {
      addToast('Routing Number is required', {
        appearance: 'error',
        autoDismiss: true,
      })
    } else {
      fetch('/', {
        method: 'POST',
        // headers: { "Content-Type": 'multipart/form-data; boundary=random' },
        body: encode(data),
      })
        .then(() => {
          addToast(
            'Kyc 2 submitted Successfully. Please do not attempt except requested to.',
            {
              appearance: 'success',
              autoDismiss: true,
            }
          )
          localStorage.setItem('kyc2', 'true')
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        })
        .catch((error) =>
          addToast('Kyc 2 submission failed.', {
            appearance: 'error',
            autoDismiss: true,
          })
        )
    }

    e.preventDefault()
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target
    if (name === 'bankName') {
      return setBankName(value)
    }
    if (name === 'accountNumber') {
      return setAccountNumber(value)
    }
    if (name === 'routingNumber') {
      return setRoutingNumber(value)
    }
  }
  return (
    <div className='formG'>
      <form onSubmit={handleSubmit} action='/thank-you/'>
        <div className='close'>
          <FaTimes onClick={() => setKyc2(false)} />
        </div>
        <div className='top'>
          <h1>KYC 2 Verification.</h1>
          <p>
            Due to our AML policies, every user is required to underatake KYC
            Verification.
          </p>
        </div>
        <div className='card'>
          <p>Bank Name: </p>
          <input
            type='text'
            name='bankName'
            value={bankName}
            onChange={handleChange}
            placeholder='Input bankName'
          />
        </div>
        <div className='card'>
          <p>Account Number: </p>
          <input
            type='text'
            name='accountNumber'
            value={accountNumber}
            onChange={handleChange}
            placeholder='Input account number'
          />
        </div>
        <div className='card'>
          <p>Routing Number: </p>
          <input
            type='text'
            name='routingNumber'
            value={routingNumber}
            onChange={handleChange}
            placeholder='Input Routing Number'
          />
        </div>
        <p>
          <button type='submit'>Submit</button>
        </p>
      </form>
    </div>
  )
}

export default Form2
