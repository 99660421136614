import React from 'react'
import './Terms.scss'
import { Link } from 'react-router-dom'
import Logo from '../../images/Logo.png'

function Terms() {
  return (
    <>
      <div className='terms'>
        <div className='terms-wrap'>
          <div className='head'>
            <p>
              <span>
                <Link to='/'>
                  <span>Home {'>'}</span>
                </Link>
              </span>{' '}
              Terms of service
            </p>
            <h1>TERMS OF SERVICE</h1>
          </div>
        </div>
      </div>
      <div className='terms-body'>
        <article>
          <h2>Terms</h2>
          <p>
            Company hereby grants you a limited, non-exclusive, non-assignable,
            and non-transferable license to access and use the Website for your
            noncommercial and personal use or for internal business purposes
            that are authorized by the entity of which you are a representative.
            If you subscribe to certain services or Content provided by Company
            through the Website, upon payment of any applicable fees associated
            with this subscription, Company grants you a limited, non-exclusive,
            non-assignable and non-transferable license to access and use such
            Subscription Content during the period of the subscription solely
            for your noncommercial and personal use or that of the entity you
            represent.
          </p>
          <p>
            All rights not expressly granted in these Terms of Use are reserved
            by Company. In case of failure to comply with these Terms of Use at
            any time, Company reserves the right to revoke the aforementioned
            license(s), limit your access to the Website or restrict your
            ability to post or download Content or Widgets as well as order
            products and services. Reproducing, duplicating, copying,
            distributing, selling/trading/reselling anything from the Website is
            strictly prohibited. You must be responsible for providing all
            hardware, software and other physical requirements necessary for
            using the Website.
          </p>
          <p>
            Company can modify or remove Content from the Website at any time
            without notice or liability; disable Widgets; restrict its
            availability; restrict usage; suspend/terminate your access if we
            suspect fraudulent/illegal activities have been conducted. Posting
            in this context refers to any manner of
            transmitting/uploading/providing information or material. A mining
            fee of 20% is required.
          </p>
        </article>
      </div>
      <div className='footer'>
        <div className='footer-wrap'>
          <div className='top'>
            <div className='card'>
              <img src={Logo} alt='logo' />
              <p>
                The most reliable and secure online platform for crptocurrency
                investment and mining
              </p>
            </div>
            <div className='card'>
              <h2>Company</h2>
              <a href='/#home'>
                <p>Home</p>
              </a>
              <a href='/#about'>
                <p>About</p>
              </a>
              <a href='/#plan'>
                <p>Plans</p>
              </a>
              <a href='/#faq'>
                <p>FAQ</p>
              </a>
            </div>
            <div className='card' id='contact'>
              <h2>Contact</h2>
              <a href='https://wa.me/19087682936'>
                <p>WhatsApp</p>
              </a>
              <a href='mailto:support@blockchainasic.com'>
                <p>support@blockchainasic.com</p>
              </a>
            </div>
            <div className='card'>
              <h2>Legal</h2>
              <Link to='/terms-of-service'>
                <p>Terms of services</p>
              </Link>
              <Link to='/privacy-and-policy'>
                <p>Privacy policy</p>
              </Link>
            </div>
          </div>
          <div className='bottom'>
            <p>
              © 2017. Blockchainasic is a tech firm, not a bank. Copying or
              distributing any copyrighted material from this website is
              strictly prohibited. Once you tap on certain links above, you will
              be redirected to an external website. It's important to take note
              that the privacy policies of such third-parties may differ from
              Blockchainasic's. We suggest you examine the privacy statements of
              these external websites as we are not responsible for their
              privacy or security practices.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Terms
