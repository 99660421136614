import React, { useState, useEffect } from 'react'
import './Home.scss'
import { TbReceiptTax } from 'react-icons/tb'
import { GiTakeMyMoney } from 'react-icons/gi'
import { RiLuggageDepositFill } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../../images/loader.gif'

function Home({ userDetails, setKyc1, setKyc2 }: any) {
  const [loading, setLoading] = useState(false)
  const authToken = sessionStorage.getItem('UserId')
  const [transact, setTransact] = useState([])
  const navigate = useNavigate()
  useEffect(() => {
    fetch(`https://blockchainaisc.herokuapp.com/user/transaction-all`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTransact(data.transactions)
          setLoading(false)
        })
      }
    })
  }, [])
  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='home_page'>
      <div className='home_button'>
{/*         {localStorage.getItem('kyc1') === 'true' ? (
          ''
        ) : (
          <button onClick={() => setKyc1(true)}>Start KYC1</button>
        )}
        {localStorage.getItem('kyc1') === 'true' &&
        localStorage.getItem('kyc2') === 'true' ? (
          ''
        ) : (
          <>
            {localStorage.getItem('kyc1') === 'true' ? (
              <button onClick={() => setKyc2(true)}>Start KYC2</button>
            ) : (
              ''
            )}
          </>
        )} */}

        <button onClick={() => navigate('/dashboard/deposit')}>Deposit</button>
      </div>
      <div className='home_tag'>
        <h1>Hello, {userDetails.name.split(' ')[0]}</h1>
      </div>
      <div className='home_card'>
        <div className='card'>
          <div className='left dif'>
            <div className='round'>
              <RiLuggageDepositFill />
            </div>
          </div>
          <div className='right'>
            <p>Deposit Amount</p>
            <h2>$ {userDetails.wallet.toLocaleString()}</h2>
          </div>
        </div>
        <div className='card'>
          <div className='left '>
            <div className='round'>
              <TbReceiptTax />
            </div>
          </div>
          <div className='right'>
            <p>Daily Profit</p>
            <h2>$ {userDetails.profit.toLocaleString()}</h2>
          </div>
        </div>
        <div className='card'>
          <div className='left dif'>
            <div className='round'>
              <GiTakeMyMoney />
            </div>
          </div>
          <div className='right'>
            <p>Available Balance</p>
            <h2>$ {userDetails.avilable.toLocaleString()}</h2>
          </div>
        </div>
      </div>
      <div className='home_tables'>
        <div className='left'>
          <div className='left_top'>
            <h2>Recent Transactions</h2>
          </div>
          {transact.length < 1 ? (
            <h2>No Transactions yet</h2>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Type</th>
                  <th>Amount</th>
                  <th>Processed</th>
                  <th>Medium</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {transact
                  .sort(
                    (a: any, b: any) =>
                      +new Date(b.createdAt) - +new Date(a.createdAt)
                  )
                  .slice(0, 10)
                  .map((item: any, i: number) => (
                    <tr key={i + 1}>
                      <td>{i + 1}</td>
                      <td>{item.type}</td>
                      <td>${item.amount.toLocaleString()}</td>
                      <td>{item.status}</td>
                      <td>{item.crypto}</td>
                      <td>
                        {new Date(item.createdAt).toDateString()},<br />
                        {new Date(item.createdAt).toLocaleTimeString()}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}

          {/* <table>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Amount</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {[...Array(6)].map((item, i) => (
                <tr key={i + 1}>
                  <td>{i + 1}</td>
                  <td>USD 2,000</td>
                  <td className='count'>This is the test</td>
                </tr>
              ))}
            </tbody>
          </table> */}
        </div>
      </div>
    </div>
  )
}

export default Home
