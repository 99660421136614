import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

const AdminRoute = ({ loggedIn }: any) => {
  const { addToast } = useToasts()
  const token = sessionStorage.getItem('AdminId')

  if (loggedIn) {
    return <Outlet />
  } else if (token) {
    return <Outlet />
  } else {
    return (
      <>
        {addToast('Access denied! You shoudlnt be here', {
          appearance: 'warning',
          autoDismiss: true,
        })}
        <Navigate to='/admini' />
      </>
    )
  }
}
export default AdminRoute
