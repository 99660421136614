import React, { useState } from 'react'
import './Deposit.scss'
import Btc from '../../../../images/btc.png'
import Bnb from '../../../../images/bnb.png'
import Eth from '../../../../images/eth.png'
import Ltc from '../../../../images/ltc.png'
import Doge from '../../../../images/doge.png'
import Xrp from '../../../../images/xrp.png'
import Loader from '../../../../images/loder.gif.gif'
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'

const CryptoList = [
  {
    id: 'btc',
    name: 'Bitcoin',
    Image: Btc,
    address: '1BpPfcWzV2uLEfbd3LN14ywiA3y45NcYL6',
  },
  {
    id: 'eth',
    name: 'Ethereum',
    Image: Eth,
    address: '0xB94c1d221580B016e104F65b7f42dA45F05aeB9f',
  },
  {
    id: 'bnb',
    name: 'BNB',
    Image: Bnb,
    address: 'bnb1dkypxe44avrrx5dn540uy6p435l7ssvd7fus25',
  },
  {
    id: 'doge',
    name: 'Dogecoin',
    Image: Doge,
    address: 'DB3EkSBFDPmFLW5MBac6jHrkJmt55aDnLK',
  },
  {
    id: 'ltc',
    name: 'Litecoin',
    Image: Ltc,
    address: 'ltc1qfrzrlp2yv4um3yl39f9pm0zlek2rgjc6qqp60h',
  },
  {
    id: 'xrp',
    name: 'Ripple ',
    Image: Xrp,
    address: 'rUkQgFn3VbiyYfdUHsPU2jh69TLi4XRpGw',
  },
]
function Deposit() {
  const token = sessionStorage.getItem('UserId')
  const [inputedAmount, setInputedAmount] = useState('1')
  const [selectedCrypto, setSelectedCrypto] = useState<any>({
    id: 'btc',
    name: 'Bitcoin',
    Image: Btc,
    address: '1BpPfcWzV2uLEfbd3LN14ywiA3y45NcYL6',
  })
  const [formLoading, setFormLoading] = useState(false)
  const { addToast } = useToasts()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>()
  const FindCyrpto = (e: string) => {
    setSelectedCrypto(CryptoList.find((item) => item.name === e))
  }
  const onSubmit = (formData: any) => {
    setFormLoading(true)
    fetch(`https://blockchainaisc.herokuapp.com/user/transaction`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...formData,
        amount: parseInt(inputedAmount),
        crypto: selectedCrypto.name,
        type: 'deposit',
      }),
    })
      .then((res) => {
        if (res.status === 201) {
          res.json().then((data) => {
            addToast('Deposit request sent. Confirmation in progress.', {
              appearance: 'success',
              autoDismiss: true,
            })
            setTimeout(() => {
              window.location.replace('/dashboard/home')
            }, 5000)
            setFormLoading(false)
          })
        } else {
          res.json().then((data) => {
            addToast(data.error, {
              appearance: 'error',
              autoDismiss: true,
            })
            setFormLoading(false)
          })
        }
      })
      .catch((err) => {
        addToast('An error ocurred. Unable to reach server', {
          appearance: 'error',
          autoDismiss: true,
        })
        setFormLoading(false)
      })
  }

  const copyToClipboard = (address: string) => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        addToast('Address copied to clipboard', {
          appearance: 'success',
          autoDismiss: true,
        })
      })
      .catch((error) => {
        addToast('Error occured while copying Address to clipboard', {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }

  // return <button onClick={copyToClipboard}>Copy text to clipboard</button>

  return (
    <div className='deposit'>
      <div className='home_tag'>
        <h1>Deposit</h1>
      </div>
      <div className='deposit-wrap'>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='card'>
              <p>Plan</p>
              <select
                id=''
                {...register('plan', {
                  required: 'Plan is required',
                })}
              >
                <option value='silver'>Silver</option>
                <option value='gold'>Gold</option>
                <option value='platinum'>Platinum</option>
              </select>
            </div>
            <div className='card'>
              <p>Cryptocurrency</p>
              <select
                id=''
                onChange={(e) => {
                  FindCyrpto(e.target.value)
                }}
              >
                {CryptoList.map((item) => (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='card'>
              <p>Amount to be Deposited ($) </p>
              <input
                type='number'
                min={1}
                defaultValue={1}
                onChange={(e) => setInputedAmount(e.target.value)}
              />
            </div>
            <div className='card'>
              <p>Amount in Cryptocurrency</p>
              <input type='text' {...register('desc')} />
            </div>
            <div className='long'>
              <div className='left'>
                <img src={selectedCrypto.Image} alt='' />
              </div>
              <div className='right'>
                <p className='one'>
                  Send only <span>{selectedCrypto.id}</span> to this address.
                  Ensure the address is correct before sending.
                </p>
                <p className='two'>Deposit Amount</p>
                <h2>${parseInt(inputedAmount).toLocaleString()}</h2>
                <div className='inputtype'>
                  <p>{selectedCrypto.address}</p>
                  <div
                    className='button'
                    onClick={() => copyToClipboard(selectedCrypto.address)}
                  >
                    Copy
                  </div>
                </div>
              </div>
            </div>
            <div className='button'>
              <p>
                Only click on the deposit button after the transfer has been
                made, you can make the transfer first and come back to fill the
                deposit form.
              </p>
              {formLoading ? (
                <img src={Loader} alt='' />
              ) : (
                <button>Deposit</button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Deposit
