import React, { useState } from 'react'
import Loader from '../../../../images/loder.gif.gif'
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'

const CryptoList = [
  {
    id: 'btc',
    name: 'Bitcoin',
  },
  {
    id: 'eth',
    name: 'Ethereum',
  },
  {
    id: 'bnb',
    name: 'BNB',
  },
  {
    id: 'doge',
    name: 'Dogecoin',
  },
  {
    id: 'ltc',
    name: 'Litecoin',
  },
  {
    id: 'xrp',
    name: 'Ripple ',
  },
]

function Withdraw() {
  const token = sessionStorage.getItem('UserId')
  const [formLoading, setFormLoading] = useState(false)
  const { addToast } = useToasts()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>()
  const onSubmit = (formData: any) => {
    setFormLoading(true)
    fetch(`https://blockchainaisc.herokuapp.com/user/transaction`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...formData,
        amount: parseInt(formData.amount),
        type: 'withdrawal',
      }),
    })
      .then((res) => {
        if (res.status === 201) {
          res.json().then((data) => {
            addToast('Withdrawal request sent. Processing...', {
              appearance: 'success',
              autoDismiss: true,
            })
            setTimeout(() => {
              window.location.replace('/dashboard/transaction')
            }, 5000)
            setFormLoading(false)
          })
        } else {
          res.json().then((data) => {
            addToast(data.error, {
              appearance: 'error',
              autoDismiss: true,
            })
            setFormLoading(false)
          })
        }
      })
      .catch((err) => {
        addToast('An error ocurred. Unable to reach server', {
          appearance: 'error',
          autoDismiss: true,
        })
        setFormLoading(false)
      })
  }
  return (
    <div className='deposit'>
      <div className='home_tag'>
        <h1>Withdraw</h1>
      </div>
      <div className='deposit-wrap'>
        <div>
          <form action='' onSubmit={handleSubmit(onSubmit)}>
            <div className='card'>
              <p>Amount to Withdraw ($) </p>
              <input
                type='number'
                {...register('amount', {
                  required: 'amount is required',
                })}
              />
            </div>
            <div className='card'>
              <p>Cryptocurrency</p>
              <select
                id=''
                {...register('crypto', {
                  required: 'crypto is required',
                })}
              >
                {CryptoList.map((item) => (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='card'>
              <p>Wallet address</p>
              <input
                type='text'
                {...register('walletAdd', {
                  required: 'walletAdd is required',
                })}
              />
            </div>
            <div className='card'>
              <p>Additional information</p>
              <input
                type='text'
                {...register('desc', {
                  required: 'desc is required',
                })}
              />
            </div>
            <div className='button' style={{ paddingTop: '50px' }}>
              {formLoading ? (
                <img src={Loader} alt='' />
              ) : (
                <button>Withdraw</button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
export default Withdraw
