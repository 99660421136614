import React, { useState, useEffect } from 'react'
import Loader from '../../../../images/loader.gif'

function Transactions() {
  const authToken = sessionStorage.getItem('UserId')
  const [loading, setLoading] = useState(true)
  const [userDetails, setUserDetails] = useState([])
  const [tab, setTab] = useState('deposit')
  useEffect(() => {
    fetch(`https://blockchainaisc.herokuapp.com/user/transaction-all`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setUserDetails(data.transactions)
          setLoading(false)
        })
      }
    })
  }, [])
  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='home_page'>
      <div className='home_tag'>
        <h1>Transactions</h1>
      </div>
      <div className='home_tables'>
        <div className='left' style={{ minHeight: '60vh' }}>
          <div className='buttons'>
            <button
              onClick={() => setTab('deposit')}
              className={tab === 'deposit' ? 'active' : ''}
            >
              Deposit
            </button>
            <button
              onClick={() => setTab('withdraw')}
              className={tab === 'withdraw' ? 'active' : ''}
            >
              Withdrawal
            </button>
          </div>
          {/* <h2>No Transactions yet</h2> */}
          {tab === 'deposit' ? (
            <table>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Plan</th>
                  <th>Amount</th>
                  <th>Processed</th>
                  <th>Medium</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {userDetails
                  .filter((item: any) => item.type === 'deposit')
                  .sort(
                    (a: any, b: any) =>
                      +new Date(b.createdAt) - +new Date(a.createdAt)
                  )
                  .map((item: any, i: number) => (
                    <tr key={i + 1}>
                      <td>{i + 1}</td>
                      <td>{item.plan}</td>
                      <td>${item.amount.toLocaleString()}</td>
                      <td>{`${item.success}`}</td>
                      <td>{item.crypto}</td>
                      <td>
                        {new Date(item.createdAt).toDateString()},<br />
                        {new Date(item.createdAt).toLocaleTimeString()}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Amount</th>
                  <th>Wallet Address</th>
                  <th>Status</th>
                  <th>Medium</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {userDetails
                  .filter((item: any) => item.type === 'withdrawal')
                  .sort(
                    (a: any, b: any) =>
                      +new Date(b.createdAt) - +new Date(a.createdAt)
                  )
                  .map((item: any, i: number) => (
                    <tr key={i + 1}>
                      <td>{i + 1}</td>
                      <td>${item.amount.toLocaleString()}</td>
                      <td>{item.walletAdd}</td>
                      <td>{item.status}</td>
                      <td>{item.crypto}</td>
                      <td>
                        {new Date(item.createdAt).toDateString()},<br />
                        {new Date(item.createdAt).toLocaleTimeString()}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  )
}

export default Transactions
