import React, { useState } from 'react'
import { TbReceiptTax } from 'react-icons/tb'
import { GiTakeMyMoney } from 'react-icons/gi'
import { MdOpenInNew } from 'react-icons/md'
import { BiUser } from 'react-icons/bi'
import Loader from '../../../../images/loader.gif'
import { useNavigate } from 'react-router-dom'

interface Props {
  userDetails: any
  mergedData: any
  isLoading: boolean
}
function Home({ userDetails, mergedData, isLoading }: Props) {
  const navigate = useNavigate()
  return isLoading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='home_page'>
      <div className='home_tag'>
        <h1>Hello, Admin</h1>
      </div>
      <div className='home_card'>
        <div className='card'>
          <div className='left dif'>
            <div className='round'>
              <BiUser />
            </div>
          </div>
          <div className='right'>
            <p>Total Users</p>
            <h2>{userDetails.userCount}</h2>
          </div>
        </div>
        <div className='card'>
          <div className='left '>
            <div className='round'>
              <TbReceiptTax />
            </div>
          </div>
          <div className='right'>
            <p>Total Daily Profits</p>
            <h2>$ {userDetails.totalProfit}</h2>
          </div>
        </div>
        <div className='card'>
          <div className='left dif'>
            <div className='round'>
              <GiTakeMyMoney />
            </div>
          </div>
          <div className='right'>
            <p>Total Available Balance</p>
            <h2>$ {userDetails.totalAvBalance}</h2>
          </div>
        </div>
      </div>
      <div className='home_tables'>
        <div className='left'>
          <div className='left_top'>
            <h2>Some Users</h2>
          </div>
          <table>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Active</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {mergedData.slice(0, 10).map((item: any, i: number) => (
                <tr key={i + 1}>
                  <td>{i + 1}</td>
                  <td>{item.name}</td>
                  <td className='count'>{item.email}</td>
                  <td>{item.phone}</td>
                  <td>{`${item.active}`}</td>
                  <td>
                    <MdOpenInNew
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigate(`/admin/edit/${item._id}`)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Home
