import { Link } from 'react-router-dom'
import Logo from '../../images/Logo.png'

function Privacy() {
  return (
    <>
      <div className='terms'>
        <div className='terms-wrap'>
          <div className='head'>
            <p>
              <span>
                <Link to='/'>
                  <span>Home {'>'}</span>
                </Link>
              </span>{' '}
              Privacy
            </p>
            <h1>Privacy Policy</h1>
          </div>
        </div>
      </div>
      <div className='terms-body'>
        <article>
          <h2>Section 1: Introduction</h2>
          <p>Title: "Privacy Policy"</p>
          <p>
            Company firmly believes that Users should be aware of how we utilize
            information gathered from them. This website is not intended for
            children under 13 years of age, and we do not knowingly collect
            personally identifiable information from minors on the internet.
            Please note that our website may contain links to other sites not
            operated or owned by us. We are not responsible for the privacy
            policies on any other websites, so please review them before
            accessing these sites. Since this website is based and hosted in the
            United States of America, if you register here, purchase services or
            products, or just use our site in general and you are based in a
            region where data collection laws differ from U.S. law, then any
            personal information that you provide to us will be transferred to
            the States and processed there. You consent to this transfer and
            processing as well as all its uses and disclosures just by providing
            us with your personal information.
          </p>
          <h2>Section 2: Information We Collect</h2>
          <p>Title: "Information We Collect"</p>
          <p>
            We can collect personal data from you in a multitude of ways:
            contact details (name, mailing address, email), demographic info
            (zip code, age and income), financial details for purchases (credit
            card number etc.), as well as other info during account
            registration. Plus, if you interact with us via email or any other
            way or post messages to forums on our website or fill surveys/forms,
            then we might collect such data too! We also track how Users
            interact with our site - such as the number of page views they have
            made - and the domains/IP addresses from where these Users originate
            from. We may use "cookies" and "web beacons" to observe User's
            behaviors when using our site. Although not all collected info is
            personally identifiable it may still be related to personal data
            provided by Users through this site or another way.
          </p>
          <h2>Section 3: How We Use Your Information</h2>
          <p>Title: "How We Use Your Information"</p>
          <p>
            We utilize anonymous general data/performance data for various
            purposes such as assessing effectiveness of training materials;
            determining characteristics of visitors; improving our site;
            marketing services; disclosing any such data to independent third
            parties for same/similar purposes (e.g., research organizations). As
            for personalized info collected, we can provide it to third-parties
            only if you've ordered their services/products; meanwhile your
            personalized data is used solely by us to give relevant info about
            our products/services - if you don't want these kinds of messages
            then there's an opt-out option available! While third-parties will
            get your personal info coupled with general info/performance data
            only when necessary - specificially if you order something from them
          </p>
          <h2>Section 4: Information Security</h2>
          <p>Title: "Information Security"</p>
          <p>
            We are committed to protecting your personal information from
            unauthorized access, use, or disclosure. We use industry-standard
            security measures to ensure the confidentiality, integrity, and
            availability of your personal data. These measures include physical,
            electronic, and administrative safeguards to protect against
            unauthorized access to systems where we store personal information.
            We also limit access to personal information to authorized personnel
            who need it to carry out their job responsibilities.
          </p>
          <h2>Section 5: Changes to Privacy Policy</h2>
          <p>Title: "Changes to Privacy Policy"</p>
          <p>
            We may update our Privacy Policy from time to time. Any changes to
            this policy will be posted on our website, and you are encouraged to
            review this policy regularly to stay informed about how we collect,
            use, and protect your personal information.
          </p>
          <h2>Section 6: Contact Us</h2>
          <p>Title: "Contact Us"</p>
          <p>
            If you have any questions or concerns about our Privacy Policy,
            please contact us.
          </p>
        </article>
      </div>
      <div className='footer'>
        <div className='footer-wrap'>
          <div className='top'>
            <div className='card'>
              <img src={Logo} alt='logo' />
              <p>
                The most reliable and secure online platform for crptocurrency
                investment and mining
              </p>
            </div>
            <div className='card'>
              <h2>Company</h2>
              <a href='/#home'>
                <p>Home</p>
              </a>
              <a href='/#about'>
                <p>About</p>
              </a>
              <a href='/#plan'>
                <p>Plans</p>
              </a>
              <a href='/#faq'>
                <p>FAQ</p>
              </a>
            </div>
            <div className='card' id='contact'>
              <h2>Contact</h2>
              <a href='https://wa.me/19087682936'>
                <p>WhatsApp</p>
              </a>
              <a href='mailto:support@blockchainasic.com'>
                <p>support@blockchainasic.com</p>
              </a>
            </div>
            <div className='card'>
              <h2>Legal</h2>
              <Link to='/terms-of-service'>
                <p>Terms of services</p>
              </Link>
              <Link to='/privacy-and-policy'>
                <p>Privacy policy</p>
              </Link>
            </div>
          </div>
          <div className='bottom'>
            <p>
              © 2017. Blockchainasic is a tech firm, not a bank. Copying or
              distributing any copyrighted material from this website is
              strictly prohibited. Once you tap on certain links above, you will
              be redirected to an external website. It's important to take note
              that the privacy policies of such third-parties may differ from
              Blockchainasic's. We suggest you examine the privacy statements of
              these external websites as we are not responsible for their
              privacy or security practices.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Privacy
