import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Topbar from '../Dashboard/Components/Topbar/Topbar'
import '../Dashboard/Dashboard.scss'
import Deposits from './Components/Deposits/Deposits'
import Edit from './Components/Edit/Edit'
import Home from './Components/Home/Home'
import Sidebar from './Components/Sidebar/Sidebar'
import Users from './Components/Users/Users'
import Withdrawals from './Components/Withdrawals/Withdrawals'
import Loader from '../../images/loader.gif'
import TransEdit from './Components/Edit/TransEdit'
import WithdrawEdit from './Components/Edit/WithdrawEdit'

function AdminDashboard() {
  const { page } = useParams<string | any>()
  const authToken = sessionStorage.getItem('AdminId')
  const [loading, setLoading] = useState(true)
  const [tloading, setTLoading] = useState(true)
  const [userDetails, setUserDetails] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [mergedData, setMergedData] = useState([])
  const [transactionData, setTransactionData] = useState([])

  async function fetchData() {
    try {
      setIsLoading(true)
      const headers = {
        Authorization: `Bearer ${authToken}`,
      }
      const users = await fetch(
        'https://blockchainaisc.herokuapp.com/user/users',
        { headers }
      )
      const transactions = await fetch(
        'https://blockchainaisc.herokuapp.com/user/transactions',
        {
          headers,
        }
      )
      const [usersData, transactionsData] = await Promise.all([
        users.json(),
        transactions.json(),
      ])
      const mergedData = usersData.users.map((user: any) => {
        const userTransactions = transactionsData.transactions.filter(
          (transaction: any) => transaction.userId === user._id
        )
        setIsLoading(false)
        return {
          ...user,
          transactions: userTransactions,
        }
      })
      setMergedData(mergedData)
    } catch (error) {
      console.error(error)
    }
  }
  async function transactionList() {
    try {
      setTLoading(true)
      const headers = {
        Authorization: `Bearer ${authToken}`,
      }
      const users = await fetch(
        'https://blockchainaisc.herokuapp.com/user/users',
        { headers }
      )
      const transactions = await fetch(
        'https://blockchainaisc.herokuapp.com/user/transactions',
        {
          headers,
        }
      )
      const [usersData, transactionsData] = await Promise.all([
        users.json(),
        transactions.json(),
      ])
      const mergedData = transactionsData.transactions.map((user: any) => {
        const owner = usersData.users.find(
          (transaction: any) => transaction._id === user.userId
        )
        setTLoading(false)
        return {
          ...user,
          owner,
        }
      })
      setTransactionData(mergedData)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    fetch(`https://blockchainaisc.herokuapp.com/admin/dashboard`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setUserDetails(data)
          setLoading(false)
        })
      }
    })
    fetch(`https://blockchainaisc.herokuapp.com/transactions`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          console.log(data)
        })
      }
    })
    fetchData()
    transactionList()
  }, [])
  const dashboardPage = () => {
    switch (page) {
      case 'home':
        return (
          <Home
            userDetails={userDetails}
            mergedData={mergedData}
            isLoading={isLoading}
          />
        )
      case 'users':
        return <Users mergedData={mergedData} isLoading={isLoading} />
      case 'deposit':
        return <Deposits mergedData={transactionData} isLoading={loading} />
      case 'withdraw':
        return <Withdrawals mergedData={transactionData} isLoading={loading} />
      case 'edit':
        return <Edit mergedData={mergedData} isLoading={isLoading} />
      case 'tranedit':
        return <TransEdit mergedData={transactionData} isLoading={tloading} />
      case 'withedit':
        return (
          <WithdrawEdit mergedData={transactionData} isLoading={tloading} />
        )

      default:
        return <h1>Page not found</h1>
    }
  }
  return (
    <div className='main-dashboard'>
      <Topbar />
      <div className='main_body'>
        <Sidebar page={page} />
        <div className='main_container'>{dashboardPage()}</div>
      </div>
    </div>
  )
}

export default AdminDashboard
