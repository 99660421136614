import React, { useState } from 'react'
import { RiHome5Line, RiLogoutCircleRLine } from 'react-icons/ri'
import { GiMoneyStack, GiReceiveMoney } from 'react-icons/gi'
import { TbBusinessplan, TbList } from 'react-icons/tb'
import { RiExchangeFundsFill, RiBankLine } from 'react-icons/ri'
import { BiUser } from 'react-icons/bi'

import { useNavigate } from 'react-router-dom'
import { useGlobalContext } from '../../../../Context/Context'

interface prop {
  page: any
}
function Sidebar({ page }: prop) {
  const navigate = useNavigate()
  const { toggleSidebar, handleSidebarToggle } = useGlobalContext()

  return (
    <div className={toggleSidebar ? 'sidebar' : 'sidebar active'}>
      <div className='columns'>
        <div
          className={page === 'home' ? 'row active' : 'row'}
          onClick={() => {
            navigate('/admin/home')
            handleSidebarToggle()
          }}
        >
          <div className='icon'>
            <RiHome5Line />
          </div>
          <p>Dashboard</p>
        </div>
        <div
          className={page === 'users' || page === 'edit' ? 'row active' : 'row'}
          onClick={() => {
            navigate('/admin/users')
            handleSidebarToggle()
          }}
        >
          <div className='icon'>
            <BiUser />
          </div>
          <p>Users</p>
        </div>
        <div
          className={
            page === 'deposit' || page === 'tranedit' ? 'row active' : 'row'
          }
          onClick={() => {
            navigate('/admin/deposit')
            handleSidebarToggle()
          }}
        >
          <div className='icon'>
            <RiBankLine />
          </div>
          <p>Deposits</p>
        </div>
        <div
          className={page === 'withdraw' ? 'row active' : 'row'}
          onClick={() => {
            navigate('/admin/withdraw')
            handleSidebarToggle()
          }}
        >
          <div className='icon'>
            <GiMoneyStack />
          </div>
          <p>Withdrawals</p>
        </div>
        <div
          className='row'
          onClick={() => {
            navigate('/admini')
            sessionStorage.clear()
          }}
        >
          <div className='icon'>
            <RiLogoutCircleRLine />
          </div>
          <p>Logout</p>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
