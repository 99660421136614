import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Plan.scss'

function Plan() {
  const navigate = useNavigate()
  return (
    <div className='plans'>
      <div className='plans-wrap'>
        <div className='top'>
          <h1>Choose an investment plan that suites you</h1>
        </div>
        <div className='wrappe'>
          <div className='crad'>
            <div className='card-heaad'>
              <h2>Silver</h2>
            </div>
            <div className='card-amount'>
              <h1>$30,000 - $50,000</h1>
            </div>
            <div className='adc'>
              <ul>
                <li> Commission - 270%</li>
                <li> Compound - Monthly</li>
                <li>Return/daily : 9%</li>
                <li>Total Estimated return : $158,000</li>
                <li> Full access to all features</li>
                <li> Personal account manager</li>
              </ul>
            </div>
            <button onClick={() => navigate('/dashboard/deposit')}>
              Deposit
            </button>
          </div>
          <div className='crad'>
            <div className='card-heaad'>
              <h2>Gold</h2>
            </div>
            <div className='card-amount'>
              <h1>$50,000 - $150,000</h1>
            </div>
            <div className='adc'>
              <ul>
                <li> Commission - 285%</li>
                <li> Compound - Monthly</li>
                <li>Return/daily : 9.5%</li>
                <li>Total Estimated return : $467,000</li>
                <li> Full access to all features</li>
                <li> Personal account manager</li>
              </ul>
            </div>
            <button onClick={() => navigate('/dashboard/deposit')}>
              Deposit
            </button>
          </div>
          <div className='crad'>
            <div className='card-heaad'>
              <h2>Platinum</h2>
            </div>
            <div className='card-amount'>
              <h1>$150,000 - $1,000,000</h1>
            </div>
            <div className='adc'>
              <ul>
                <li> Commission - 300%</li>
                <li> Compound - Monthly</li>
                <li>Return/daily : 10%</li>
                <li>Total Estimated return : $3,100,000</li>
                <li> Full access to all features</li>
                <li> Personal account manager</li>
              </ul>
            </div>
            <button onClick={() => navigate('/dashboard/deposit')}>
              Deposit
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Plan
