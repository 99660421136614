import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import './Edit.scss'
import Loader from '../../../../images/loader.gif'
import Loaderr from '../../../../images/loder.gif.gif'
import { useToasts } from 'react-toast-notifications'

interface Props {
  mergedData: any
  isLoading: boolean
}
function TransEdit({ mergedData, isLoading }: Props) {
  const { addToast } = useToasts()
  const authToken = sessionStorage.getItem('AdminId')
  const [loading, setLoading] = useState(true)
  const { id } = useParams()
  const [user, setUser] = useState<any>({})
  const [userStatus, setUserStatus] = useState(0)
  const [statusLoading, setstatusLoading] = useState(false)
  const [profit, setProfit] = useState(0)
  const [avilable, setAvilable] = useState(0)
  const [deposit, setDeposit] = useState(0)

  useEffect(() => {
    if (!isLoading) {
      const findUser = mergedData.find((item: any) => item._id === id)
      setUser(findUser)
      setLoading(false)
    }
  }, [isLoading])

  const updateUserStatus = () => {
    setstatusLoading(true)
    fetch(
      `https://blockchainaisc.herokuapp.com/user/transaction/approve?id=${id}`,
      {
        method: 'put',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          profit: userStatus,
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setstatusLoading(false)
            addToast('Profit Added to user', {
              appearance: 'success',
              autoDismiss: true,
            })
            setTimeout(() => {
              window.location.reload()
            }, 4000)
          })
        } else {
          res.json().then((data) => {
            addToast(data.error, {
              appearance: 'error',
              autoDismiss: true,
            })
            setstatusLoading(false)
          })
        }
      })
      .catch(() => {
        setstatusLoading(false)
      })
  }

  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='deposit'>
      <div className='home_tag'>
        <h1>Update Deposit</h1>
      </div>
      <div className='deposit-wrap'>
        <div>
          <form action=''>
            <div className='card'>
              <p>Name</p>
              <div className='inputy'>
                <p>{user.owner.name}</p>
              </div>
            </div>
            <div className='card'>
              <p>Email</p>
              <div className='inputy'>
                <p>{user.owner.email}</p>
              </div>
            </div>
            <div className='card'>
              <p>Amount</p>
              <div className='inputy'>
                <p>${user.amount.toLocaleString()}</p>
              </div>
            </div>
            <div className='card'>
              <p>Plan</p>
              <div className='inputy'>
                <p>{user.plan}</p>
              </div>
            </div>
            <div className='card'>
              <p>Crypto</p>
              <div className='inputy'>
                <p>{user.crypto}</p>
              </div>
            </div>
            <div className='card'>
              <p>Amount in Cryptocurrency</p>
              <div className='inputy'>
                <p>{user.desc}</p>
              </div>
            </div>
            <div className='card'>
              <p>Resloved</p>
              <div className='inputy'>
                <p>{`${user.success}`}</p>
              </div>
            </div>
            <div className='card'>
              <p>Date</p>
              <div className='inputy'>
                <p>
                  {new Date(user.createdAt).toDateString()},
                  {new Date(user.createdAt).toLocaleTimeString()}
                </p>
              </div>
            </div>
          </form>
        </div>
        {!user.success && (
          <div className='edit-status'>
            <h2>Resolve by Entering the Profit</h2>
            <form action=''>
              <div className='card'>
                <input
                  type='number'
                  onChange={(e) => setUserStatus(parseInt(e.target.value))}
                />
                {statusLoading ? (
                  <img src={Loaderr} alt='' />
                ) : (
                  <button onClick={updateUserStatus}>Enter</button>
                )}
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  )
}

export default TransEdit
