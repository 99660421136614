import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import './Edit.scss'
import Loader from '../../../../images/loader.gif'
import Loaderr from '../../../../images/loder.gif.gif'
import { useToasts } from 'react-toast-notifications'

interface Props {
  mergedData: any
  isLoading: boolean
}
function Edit({ mergedData, isLoading }: Props) {
  const { addToast } = useToasts()
  const authToken = sessionStorage.getItem('AdminId')
  const [loading, setLoading] = useState(true)
  const { id } = useParams()
  const [user, setUser] = useState<any>([])
  const [userStatus, setUserStatus] = useState<boolean>()
  const [tip, setTip] = useState(0)
  const [tipLoading, setTipLoading] = useState(false)
  const [profit, setProfit] = useState(0)
  const [avilable, setAvilable] = useState(0)
  const [deposit, setDeposit] = useState(0)

  const [statusLoading, setstatusLoading] = useState(false)
  useEffect(() => {
    if (!isLoading) {
      const findUser = mergedData.find((item: any) => item._id === id)
      setUser(findUser)
      setLoading(false)
      setUserStatus(findUser.active)
      setProfit(findUser.profit)
      setAvilable(findUser.avilable)
      setDeposit(findUser.wallet)
    }
  }, [isLoading])

  const updateUserStatus = () => {
    setstatusLoading(true)
    fetch(`https://blockchainaisc.herokuapp.com/admin/user/status`, {
      method: 'put',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        id: id,
        status: userStatus,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setstatusLoading(false)
            addToast(
              `user status has been set to ${
                userStatus === true ? 'Active' : 'InActive'
              }`,
              {
                appearance: 'success',
                autoDismiss: true,
              }
            )
            setTimeout(() => {
              window.location.reload()
            }, 4000)
          })
        } else {
          res.json().then((data) => {
            setstatusLoading(false)
          })
        }
      })
      .catch(() => {
        setstatusLoading(false)
      })
  }
  const updateTip = () => {
    setTipLoading(true)
    fetch(`https://blockchainaisc.herokuapp.com/user/tip?id=${id}`, {
      method: 'put',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        amount: tip,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setTipLoading(false)
            addToast('Tip Added to user', {
              appearance: 'success',
              autoDismiss: true,
            })
            setTimeout(() => {
              window.location.reload()
            }, 4000)
          })
        } else {
          res.json().then((data) => {
            addToast(data.error, {
              appearance: 'error',
              autoDismiss: true,
            })
            setTipLoading(false)
          })
        }
      })
      .catch(() => {
        setTipLoading(false)
      })
  }
  const updateUserInfo = () => {
    setstatusLoading(true)
    fetch(`https://blockchainaisc.herokuapp.com/user/baledit?id=${id}`, {
      method: 'put',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        profit: profit,
        avilable: avilable,
        wallet: deposit,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setstatusLoading(false)
            addToast('User details Edited user', {
              appearance: 'success',
              autoDismiss: true,
            })
            setTimeout(() => {
              window.location.reload()
            }, 4000)
          })
        } else {
          res.json().then((data) => {
            addToast(data.error, {
              appearance: 'error',
              autoDismiss: true,
            })
            setstatusLoading(false)
          })
        }
      })
      .catch(() => {
        setstatusLoading(false)
      })
  }

  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='deposit'>
      <div className='home_tag'>
        <h1>Edit User</h1>
      </div>
      <div className='deposit-wrap'>
        <div>
          <form action=''>
            <div className='card'>
              <p>Name</p>
              <div className='inputy'>
                <p>{user.name}</p>
              </div>
            </div>
            <div className='card'>
              <p>Email</p>
              <div className='inputy'>
                <p>{user.email}</p>
              </div>
            </div>
            <div className='card'>
              <p>Phone</p>
              <div className='inputy'>
                <p>{user.phone}</p>
              </div>
            </div>
            <div className='card'>
              <p>Status</p>
              <div className='inputy'>
                <p>{`${user.active}`}</p>
              </div>
            </div>
            <div className='card'>
              <p>Password</p>
              <div className='inputy'>
                <p>{user.password}</p>
              </div>
            </div>
            <div className='card'>
              <p>Deposit</p>
              <div className='inputy'>
                <p>${user.wallet}</p>
              </div>
            </div>
            <div className='card'>
              <p>Daily Profit</p>
              <div className='inputy'>
                <p>${user.profit}</p>
              </div>
            </div>
            <div className='card'>
              <p>Balance</p>
              <div className='inputy'>
                <p>{user.avilable}</p>
              </div>
            </div>
          </form>
        </div>
        <div className='edit-status'>
          <h2>Edit User Status</h2>
          <form action=''>
            <div className='card'>
              <select
                name=''
                id=''
                onChange={(e) => {
                  if (e.target.value === 'true') {
                    setUserStatus(true)
                  } else {
                    setUserStatus(false)
                  }
                }}
              >
                <option value={`${user.active}`}>
                  {user.active === true ? 'Active' : 'Inactive'}
                </option>
                {user.active === true ? (
                  <option value={'false'}>Inactive</option>
                ) : (
                  <option value={'true'}>Active</option>
                )}
              </select>
              {statusLoading ? (
                <img src={Loaderr} alt='' />
              ) : (
                <button onClick={updateUserStatus}>Change</button>
              )}
            </div>
          </form>
        </div>
        <div className='edit-status'>
          <h2>Edit User Details</h2>
          <div className='form'>
            <div className='card'>
              <p>Daily Profit</p>
              <input
                type='number'
                onChange={(e) => setProfit(parseInt(e.target.value))}
                defaultValue={profit}
              />
            </div>
            <div className='card'>
              <p>Available</p>
              <input
                type='number'
                onChange={(e) => setAvilable(parseInt(e.target.value))}
                defaultValue={avilable}
              />
            </div>
            <div className='card'>
              <p>Deposit</p>
              <input
                type='number'
                onChange={(e) => setDeposit(parseInt(e.target.value))}
                defaultValue={deposit}
              />
            </div>
            <div className='button'>
              {tipLoading ? (
                <img src={Loaderr} alt='' />
              ) : (
                <button onClick={updateUserInfo}>Enter</button>
              )}
            </div>
          </div>
        </div>
        <div className='edit-status'>
          <h2>Tip User</h2>
          <form action=''>
            <div className='card'>
              <input
                type='number'
                onChange={(e) => setTip(parseInt(e.target.value))}
              />
              {tipLoading ? (
                <img src={Loaderr} alt='' />
              ) : (
                <button onClick={updateTip}>Enter</button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Edit
