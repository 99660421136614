import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/Logo.png'
import HeroImg from '../../images/Hue_Saturation-1.png'
import AboutImg from '../../images/choong-deng-xiang-rVHIqG9tTOE-unsplash.jpg'
import './Landing.scss'
import { FaPlus, FaMinus } from 'react-icons/fa'
import {
  useCryptoPrices,
  useCryptoTickers,
  CryptoPriceProvider,
} from 'react-realtime-crypto-prices'

const HookExample = () => {
  const prices = useCryptoPrices(['btc', 'eth', 'usdt', 'bnb'])
  const tickers = useCryptoTickers(['btc', 'eth'])
  return (
    <>
      <div>Live Prices</div>
      <div>{JSON.stringify(prices)}</div>

      <div>Live Tickers</div>
      <div>{JSON.stringify(tickers)}</div>
    </>
  )
}

const FaqList = [
  {
    header: ' When can I deposit/withdraw from my Investment account?',
    content: `Once you've made and confirmed your investment deposit, deposit and withdrawal are both available. Make sure your mining processes have been completed before requesting to withdraw; you'll be able to see your available balance on the dashboard on the main page.`,
  },
  {
    header: 'How do I check my account balance?',
    content:
      'Whenever you need to, you can view this information on your accounts dashboard.',
  },
  {
    header: 'How will I know that the withdrawal has been successful?',
    content: `Once we've sent the funds, you'll be notified automatically and you can always check your transactions or account balance. The timing of when the funds reach you will depend on which payment system you chose.`,
  },
  {
    header: ' How much can I withdraw?',
    content:
      'You can withdraw the full balance of your account, with the exception of any funds currently being used to support mining operations on your Portfolio.',
  },
]
function Landing() {
  const [showNav, setShowNav] = useState<string | boolean>('false')
  const [navigatePage, setNavigatePage] = useState('')
  const [selectedQuest, setSelectedQuest] = useState({
    header: '',
    content: '',
  })
  return (
    <div className='landing'>
      <div className='nav-wrap'>
        <div className='navbar'>
          <div className='nav_contain'>
            <div className='logo'>
              <a href='#home'>
                <img src={Logo} alt='' />
              </a>
            </div>
            <div
              onClick={() => setShowNav(!showNav)}
              className={showNav ? 'hamburger' : 'hamburger toggle'}
            >
              <div className='line1'></div>
              <div className='line2'></div>
              <div className='line3'></div>
            </div>
            <ul className={showNav ? 'nav_list' : 'nav_list open'}>
              <a href='#home'>
                <li
                  onClick={() => {
                    setNavigatePage('home')
                    setShowNav(!showNav)
                  }}
                  className={navigatePage === 'home' ? 'active' : ''}
                >
                  Home
                </li>
              </a>
              <a href='#about'>
                <li
                  onClick={() => {
                    setNavigatePage('about')
                    setShowNav(!showNav)
                  }}
                  className={navigatePage === 'about' ? 'active' : ''}
                >
                  About Us
                </li>
              </a>
              <a href='#plan'>
                <li
                  onClick={() => {
                    setNavigatePage('plans')
                    setShowNav(!showNav)
                  }}
                  className={navigatePage === 'plans' ? 'active' : ''}
                >
                  Plans
                </li>
              </a>
              <a href='#faq'>
                <li
                  onClick={() => {
                    setNavigatePage('faq')
                    setShowNav(!showNav)
                  }}
                  className={navigatePage === 'faq' ? 'active' : ''}
                >
                  Faq
                </li>
              </a>
              <Link to='/login'>
                <li>
                  <button>Login</button>
                </li>
              </Link>
              <Link to='/register'>
                <li>
                  <button>Sign up</button>
                </li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-wrap'>
          <div className='left'>
            <h1>Invest to Grow Your Money​</h1>
            <p>
              Blockchainasic is the most reliable and secure online platform
              that makes trading, investing, mining, stocks and cryptocurrency
              easy and safe. And it supports a variety of the most popular
              digital currencies.
            </p>
            <Link to='/register'>
              <button>Get Started</button>
            </Link>
          </div>
          <div className='right'>
            <img src={HeroImg} alt='' />
          </div>
        </div>
      </div>
      {/* <CryptoPriceProvider cryptoCompareApiKey='cc4c831f179eacb98e80b44e89c251f67c89ca9c88a6152a3f70754f567d1fd5'>
        <HookExample />
      </CryptoPriceProvider> */}
      <div className='about' id='about'>
        <div className='about-wrap'>
          <div className='left'>
            <img src={AboutImg} alt='' />
          </div>
          <div className='right'>
            <h1>About us</h1>
            <p>
              At Blockchainasic, our mission is to automate the investment
              process for our clients and to generate returns without the need
              for a third party. We achieve this by allocating hashing power
              from our mining pool to investor wallets. This allows investors to
              stay financially relevant and generate passive income without
              needing to monitor the mining process constantly. The mining
              experts at Blockchainasic use the most profitable hardware miners
              on the market (CGminer) so that our clients can get the best
              returns from their investments.
            </p>
            <p>
              Once you have verified your account, you can begin your mining
              process. You have full control over your investment through the
              Blockchainasic dashboard where you can monitor and manage your
              investments. You can make a withdrawal once you reach your
              withdrawal threshold, and there is no need to send money to any of
              our online agents.
            </p>
            <div className='goal'>
              <h2>Our Goal</h2>
              <p>
                We seek to build value through portfolio management in order to
                assist our clients with achieving their long-term financial
                aspirations. Our investment approaches are based on our values
                and principles, while also providing our employees with an
                exciting and rewarding work environment to grow in.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='plans' id='plan'>
        <div className='plans-wrap'>
          <div className='top'>
            <h1>Choose an investment plan that suites you</h1>
          </div>
          <div className='wrappe'>
            <div className='crad'>
              <div className='card-heaad'>
                <h2>Silver</h2>
              </div>
              <div className='card-amount'>
                <h1>$30,000 - $50,000</h1>
              </div>
              <div className='adc'>
                <ul>
                  <li> Commission - 270%</li>
                  <li> Compound - Monthly</li>
                  <li>Return/daily : 9%</li>
                  <li>Total Estimated return : $158,000</li>
                  <li> Full access to all features</li>
                  <li> Personal account manager</li>
                </ul>
              </div>
              <Link to='/register'>
                <button>Get Started</button>
              </Link>
            </div>
            <div className='crad'>
              <div className='card-heaad'>
                <h2>Gold</h2>
              </div>
              <div className='card-amount'>
                <h1>$50,000 - $150,000</h1>
              </div>
              <div className='adc'>
                <ul>
                  <li> Commission - 285%</li>
                  <li> Compound - Monthly</li>
                  <li>Return/daily : 9.5%</li>
                  <li>Total Estimated return : $467,000</li>
                  <li> Full access to all features</li>
                  <li> Personal account manager</li>
                </ul>
              </div>
              <Link to='/register'>
                <button>Get Started</button>
              </Link>
            </div>
            <div className='crad'>
              <div className='card-heaad'>
                <h2>Platinum</h2>
              </div>
              <div className='card-amount'>
                <h1>$150,000 - $1,000,000</h1>
              </div>
              <div className='adc'>
                <ul>
                  <li> Commission - 300%</li>
                  <li> Compound - Monthly</li>
                  <li>Return/daily : 10%</li>
                  <li>Total Estimated return : $3,100,000</li>
                  <li> Full access to all features</li>
                  <li> Personal account manager</li>
                </ul>
              </div>
              <Link to='/register'>
                <button>Get Started</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='faq' id='faq'>
        <div className='faq-wrap'>
          <div className='top'>
            <h1> Frequently asked Questions</h1>
            <p>Answers to some common questions about Blockchainasic</p>
          </div>
          <div className='listt'>
            <div className='list'>
              {FaqList.map((item) => (
                <div
                  className={
                    selectedQuest.header === item.header
                      ? 'card active'
                      : 'card'
                  }
                  key={item.header}
                >
                  <header onClick={() => setSelectedQuest(item)}>
                    <h2>{item.header}</h2>
                    {selectedQuest.header === item.header ? (
                      <FaMinus />
                    ) : (
                      <FaPlus />
                    )}
                  </header>
                  <article>{item.content}</article>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='footer'>
        <div className='footer-wrap'>
          <div className='top'>
            <div className='card'>
              <img src={Logo} alt='logo' />
              <p>
                The most reliable and secure online platform for crptocurrency
                investment and mining
              </p>
            </div>
            <div className='card'>
              <h2>Company</h2>
              <a href='#home'>
                <p>Home</p>
              </a>
              <a href='#about'>
                <p>About</p>
              </a>
              <a href='#plan'>
                <p>Plans</p>
              </a>
              <a href='#faq'>
                <p>FAQ</p>
              </a>
            </div>
            <div className='card' id='contact'>
              <h2>Contact</h2>
              <a href='https://wa.me/19087682936'>
                <p>WhatsApp</p>
              </a>
              <a href='mailto:support@blockchainasic.com'>
                <p>support@blockchainasic.com</p>
              </a>
            </div>
            <div className='card'>
              <h2>Legal</h2>
              <Link to='/terms-of-service'>
                <p>Terms of services</p>
              </Link>
              <Link to='/privacy-and-policy'>
                <p>Privacy policy</p>
              </Link>
            </div>
          </div>
          <div className='bottom'>
            <p>
              © 2017. Blockchainasic is a tech firm, not a bank. Copying or
              distributing any copyrighted material from this website is
              strictly prohibited. Once you tap on certain links above, you will
              be redirected to an external website. It's important to take note
              that the privacy policies of such third-parties may differ from
              Blockchainasic's. We suggest you examine the privacy statements of
              these external websites as we are not responsible for their
              privacy or security practices.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Landing
