import React, { useState, useEffect } from 'react'
import Loader from '../../../../images/loader.gif'
import { MdOpenInNew } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

interface Props {
  isLoading: boolean
  mergedData: any
}
function Users({ mergedData, isLoading }: Props) {
  const navigate = useNavigate()

  return isLoading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='home_page'>
      <div className='home_tag'>
        <h1>Users</h1>
      </div>
      <div className='home_tables'>
        <div className='left' style={{ minHeight: '60vh' }}>
          <table>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Active</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {mergedData.map((item: any, i: number) => (
                <tr key={i + 1}>
                  <td>{i + 1}</td>
                  <td>{item.name}</td>
                  <td className='count'>{item.email}</td>
                  <td className='count'>{item.phone}</td>
                  <td>{`${item.active}`}</td>
                  <td>
                    <MdOpenInNew
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigate(`/admin/edit/${item._id}`)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Users
